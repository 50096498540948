import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Line from '../components/Line';

import AnimatedCountUpDecimal from '../components/UX/AnimatedCountUpDecimal';
import FadeInAnimation from '../components/UX/FadeInAnimation';
import MaskSlidInAnimation from '../components/UX/MaskSlideInAnimation';
import MagicText from '../components/UX/MagicText';
import ImgTextModule from '../components/elements/ImgTextModule';
import RouterLink from '../components/Utility/Routing/RouterLink';
import InViewTrigger from '../components/Utility/UX/InViewTrigger';
import Headerelement from '../components/elements/Headerelement';
import GVideo from '../components/Utility/GVideo';
import GImage from '../components/Utility/GImage';
const IndexPage = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode('light');
  });

  return (
    <>
      <Helmet>
        <title>KDW | Tarifvertrag</title>
        <meta
          name='description'
          content='kdw HR bietet Kunden Dienstleistungen von der Personalvermittlung, über qualifizierte Arbeitnehmerüberlassung bis hin zu Management- und Fördermittelberatung im gesamten Personalmanagementbereich. Arbeitnehmer finden bei uns langfristige Karriereoptionen genauso wie neue Herausforderungen vornehmlich im Industrie-, Gewerbe- und Officesegment'
        />
        <link rel='canonical' href='https:/kdw-hr.de' />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Helmet>

      {/* CONTAINER MAGICTEXT */}
      <ImgTextModule
        src={'back2.png'}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={false}
        h='65vh'
        mediaOpacity={0.9}
        bgColor='brand.violet'
      >
        <Container bgColor='brand.violet' variant='layoutContainer'>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Text
              color='brand.gray.1000'
              fontWeight='normal'
              fontSize={{ base: '5xl', lg: '7xl' }}
              mb={12}
            >
              Tarifvertrag
            </Text>
          </FadeInAnimation>
        </Container>
      </ImgTextModule>
      <Box bgColor='brand.violet'>
        <Container
          bgColor='brand.violet'
          variant='layoutContainer'
          pt={{ base: '0%', md: '00vh' }}
        >
          <Box w={{ lg: '50%', base: '100%' }}>
            <InViewTrigger threshold={0.4} triggerOnce={false}>
              {(inView) => (
                <Stack
                  direction={{ base: 'column', md: 'column' }}
                  spacing={4}
                  align='stretch'
                >
                  <Box minW={'100%'}></Box>
                  <Box>
                    <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
                      <Button
                        px={20}
                        pt={6}
                        pb={10}
                        fontSize='lg'
                        bg={'brand.yellow'}
                        textAlign='center'
                        variant={'solid'}
                      >
                        <RouterLink
                          isExternal={true}
                          link={'../tarifvertrag.pdf'}
                        >
                          Tarifvertrag
                        </RouterLink>
                      </Button>
                    </FadeInAnimation>

                    <MaskSlidInAnimation threshold={1} duration={0.6}>
                      <Text mt={4} pl={4} color={'brand.gray.1000'}>
                        Bequem als PDF.
                      </Text>
                    </MaskSlidInAnimation>
                  </Box>
                </Stack>
              )}
            </InViewTrigger>
          </Box>
          <Box w={{ lg: '50%', base: '100%' }}>
            <InViewTrigger threshold={0.4} triggerOnce={false}>
              {(inView) => (
                <Stack
                  direction={{ base: 'column', md: 'column' }}
                  spacing={4}
                  align='stretch'
                >
                  <Box minW={'100%'}></Box>
                  <Box>
                    <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
                      <Button
                        px={20}
                        pt={6}
                        pb={10}
                        fontSize='lg'
                        bg={'brand.yellow'}
                        textAlign='center'
                        variant={'solid'}
                      >
                        <RouterLink
                          isExternal={true}
                          link={
                            '../tarifDSGVO_Mitarbeiterinformation_zur_Datenverarbeitung.pdf'
                          }
                        >
                          DSGVO Mitarbeiterinformation
                        </RouterLink>
                      </Button>
                    </FadeInAnimation>

                    <MaskSlidInAnimation threshold={1} duration={0.6}>
                      <Text mt={4} pl={4} color={'brand.gray.1000'}>
                        Bequem als PDF.
                      </Text>
                    </MaskSlidInAnimation>
                  </Box>
                </Stack>
              )}
            </InViewTrigger>
          </Box>
        </Container>
      </Box>

      <Box w='100%' bg={'brand.violet'}>
        <Container variant='layoutContainer' px={10} py={20}></Container>
      </Box>
    </>
  );
};

export default IndexPage;
